
/* roboto-100 - latin */
/* @font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"),
    url("./fonts/roboto/roboto-v20-latin-100.woff2") format("woff2"),
    url("./fonts/roboto/roboto-v20-latin-100.woff") format("woff");
} */
/* roboto-regular - latin */
/* @font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("./fonts/roboto/roboto-v20-latin-regular.woff2")  format("woff2"),
    url("./fonts/roboto/roboto-v20-latin-regular.woff") format("woff");
} */
/* roboto-700 - latin */
/* @font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("./fonts/roboto/roboto-v20-latin-700.woff2") format("woff2"),
    url("./fonts/roboto/roboto-v20-latin-700.woff") format("woff");
} */
/* roboto-900 - latin */
/* @font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"),
    url("./fonts/roboto/roboto-v20-latin-900.woff2") format("woff2"),
      url("./fonts/roboto/roboto-v20-latin-900.woff") format("woff"); 
}*/


body{
  font-family: Arial,Verdana, Geneva, Tahoma, sans-serif;
}

.page-section {
  padding: 6rem 0;
}
.bg-blue {
  background-color: #1a1e53 !important;
}


.bg-blue-light {
  background-color: #f2fcfe !important;
}

.masthead {
  background-color: #f2fcfe;
  background: linear-gradient(180deg, rgba(41,237,249,0.5) 0%, rgba(255,255,255,1) 100%),url(./img/bg_letter.webp);
  background-repeat: no-repeat;
  background-position: 100% 0%;
  /* background-size: 160%; */
  /* background: url(./img/header_main_page.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: scroll; */
  /* background-size: cover; */
}
@media (max-width: 992px) {
  .masthead {
    background-position: 50% 150%;
  }
}


#two-graphs {
  background-image: url(./img/graph-bg.svg);
  background-position: bottom center;
  background-size: auto;
  background-repeat: no-repeat;
}

.container_footer {
  background-image: url(./img/map-bg.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}


.bg-tri {
  background-color: #f2fcfe;
  background: linear-gradient(180deg, rgba(41,237,249,0.5) 0%, rgba(255,255,255,1) 100%);
  /* background-image: url(./img/header-bg.jpg);
  background-position: center center;
  background-size: cover; */
}

a.active{
  color:#62bde7 !important;
  font-weight: bold;
  border-bottom: 2px solid #62bde7;
}

.btn-primary{
  background-color: #62bde7;
  border-color: #62bde7;
}

.divider{
  border-bottom: 10px solid;
border-color: #62bde7;
}